body {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito Sans", sans-serif;
  background-color: #f3f3f3;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}
.navbar {
  background-color: #ffffff;
  height: 50px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.navbar-brand {
  width: 50px;
  height: 50px;
  padding: 0;
  margin: 0;
  margin-left: 2rem;
  margin-top: 25px;
  z-index: 10;
}

/*navbar shadow */
.navbar-shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #ffffff;
  padding-right: 20px;
}

/* Float the list items side by side */
.navbar li {
  float: right;
}

/* Style the links inside the list items */
.navbar li a {
  display: block;
  color: #000000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

/* Change background color of links on hover */
.navbar li a:hover {
  background-color: #ddd;
}
/* Style the active/current link */
.navbar li a.active {
  background-color: #4caf50;
  color: white;
}

/* Style the content */
.content {
  /* margin-top: 50px; Same as the height of the navbar */
}

.ReactModal__Content {
  border: 0px !important;
  background-color: transparent !important;
}
.ReactModal__Content--after-open {
  border: 0px !important;
  background-color: transparent !important;
}
.my-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.bell {
  color: #241bd0;
  font-weight: bold;
  font-size: x-large;
}

.pnd-blue {
  color: #241bd0 !important;
}

.yellow-line {
  border-bottom: 2px solid #f0ad4e !important;
}

.pnd-text-gray {
  color: #a6a6a6 !important;
}

.popup-content {
  background-color: transparent !important;
  width: auto !important;
  padding: 0 !important;
  border: none !important;
}

.rotate-icon-90 {
  transform: rotate(90deg);
}

.rotate-icon-0 {
  transform: rotate(0deg);
}
.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.3) !important;
}
/*********** page loader or spinner ***********/

.loader-container {
  height: 100vh;
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.loader-small {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #fff;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/************** ***********/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  font-weight: 800;
}
/** placeholder fornt weight to 800 of all input */

::-webkit-input-placeholder {
  /* Edge */
  font-weight: 800;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-weight: 800;
}

::placeholder {
  font-weight: 800;
}

.min-login-height {
  min-height: 400px;
}
/* 
button {
  max-width: 277px;
} */

.image_placeholder {
  background: url("http://placehold.jp/150x150.png") no-repeat;
  width: 100%;
  height: 200px;
}

.menu {
  width: 200px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}
.menu-item {
  cursor: pointer;
  padding: 5px;
  height: 35px;
  border-bottom: 1px solid rgb(187, 187, 187);
}
.menu-item:hover {
  color: #2980b9;
}

.popup-content {
  top: 64px !important;
  right: 0% !important;
}
